import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Card, CardBody, CardHeader, Col, Label, Container, Row, Form, Spinner, Table, FormFeedback, Input, Alert } from 'reactstrap';
import { Link, useParams } from "react-router-dom";
import "cleave.js/dist/addons/cleave-phone.br.js";
import { useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginGetFile from 'filepond-plugin-get-file';

import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { api } from "../../utils/api";
import Flatpickr from 'react-flatpickr';
import { flatpickrLocale } from "../../utils/flatpickrLocale";
import { format } from 'date-fns';
import BaseExampleVoters from "./GridTablesDataVoters";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginGetFile);


export function VoterBirthDateMessage() {
    document.title = "Envio de Aniversário | Geld";

    const navigate = useNavigate();
    const [files, setFiles] = useState<File[]>([]);
    const [data, setData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setisLoading] = useState(false);
    const [selectSortRow, setselectSortRow] = useState('name');
    const [sortDirection, setsortDirection] = useState('asc');

    interface FormData {
        message: string;
        send_time: string;
        active: string;
    };

    const [formData, setFormData] = useState<FormData>({
        message: '',
        send_time: '19:00',
        active: 'Y',
    });

    const [formValidationMessages, setFormValidationMessages] = useState({
        message: '',
        send_time: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
    };

    const handleChangeMessage = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeSendTime = (timeString: string) => {
        setFormData({ ...formData, send_time: timeString || '19:00' });
    };

    const handleChangeActive = (checked: boolean) => {
        setFormData({ ...formData, active: checked ? 'Y' : 'N' });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage: number) => {
        setCurrentPage(1);
        setRowsPerPage(newRowsPerPage);
    };

    const handleSort = (selectSortRow: any, sortDirection: string) => {
        switch (selectSortRow.id) {
            case 1:
                setselectSortRow('name')
                break;
            case 2:
                setselectSortRow('classification')
                break;
            case 4:
                setselectSortRow('phone')
                break;
            case 5:
                setselectSortRow('neighborhood')
                break;
            default:
                break;
        }
        setsortDirection(sortDirection)
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchVoters();
    }, [currentPage, rowsPerPage, selectSortRow, sortDirection,]);

    const fetchData = async () => {
        try {

            const responseConfig = await api.get('/getVoterBirthDateMessage');

            setFormData({
                message: responseConfig.data?.message ?? '',
                send_time: responseConfig.data?.send_time ?? '19:00',
                active: responseConfig.data?.active ?? 'Y'
            });

            if (responseConfig.data?.archive) {
                const archive = responseConfig.data.archive || '';
                const response = await fetch(process.env.REACT_APP_API_URL + 'getVoterBirthDateMessageArchive/' + archive);
                // const response = await fetch((process.env.REACT_APP_API_URL + 'getVoterBirthDateMessageArchive/') + responseConfig.data.archive ?? '');
                const data = await response.blob();
                const convertedFile = new File([data], responseConfig.data.archive ?? '', { type: data.type });
                setFiles([convertedFile]);
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchVoters = async () => {
        setisLoading(true);

        setFormData((prevState) => ({ ...prevState, votersIds: [] }));

        console.log(format(new Date(), 'dd/MM'));

        await api.post('/searchVoters', {
            params: {
                formData: {
                    birth_date: format(new Date(), 'dd/MM'),
                    neighborhood: '',
                    electoral_zone: '',
                    group: '',
                    section: '',
                    education: '',
                    address: '',
                    birth_year: '',
                    number_classification: '',
                    profession: '',
                    created_by: '',
                    gender: '',
                    otherGender: '',
                },
                page: currentPage,
                perPage: rowsPerPage,
                selectSortRow: selectSortRow,
                sortDirection: sortDirection,
                withPluckedIds: true,
            },
        })
            .then((response) => {
                setTotalRows(response.data.totalCount);

                setData(response.data.data);
                setFormData((prevState) => ({
                    ...prevState,
                    votersIds: response.data.pluckedIds?.map((voterId: number) => {
                        return 'id-' + voterId;
                    }) ?? [],
                }));
            })
            .catch((error) => {
                // Handle the error response
                console.error(error);
            });

        setisLoading(false);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const isValid = validateFormData();
        if (!isValid) {
            Swal.fire('Erro', `Verifique os erros do formulário`, 'error');
            return false;
        }

        var formFile = new FormData();
        if (files.length) {
            formFile.append('image', files[0], files[0].name);
        }
        Object.entries(formData).forEach(([key, value]) => {
            formFile.append(key, String(value));
        });
        api.post('/updateVoterBirthDateMessage', formFile)
            .then(response => {
                Swal.fire('Sucesso', 'Envio Atualizado com Sucesso!', 'success');
            })
            .catch(error => {
                // Handle the error response
                Swal.fire('Erro', 'Falha na atualização de envio!', 'error');
            });
    };

    function validateFormData() {
        const errors = {
            send_time: '',
            message: '',
        };

        let hasError = false;

        if (!formData.send_time) {
            hasError = true;
            errors.send_time = 'A hora de envio é obrigatória';
        }
        if (!formData.message && files.length === 0) {
            hasError = true;
            errors.message = 'É obrigatório informar a mensagem ou o anexo de mídia';
        }

        setFormValidationMessages(errors);

        return !hasError;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Envio de Aniversário</h4>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <Alert color="warning">
                                                Enquanto estiver ativo será enviado a mensagem automaticamente para todos os eleitores aniversariantes.
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <span className="text-danger">* Campos obrigatórios</span>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex align-items-center">
                                            <Col lg={3}>
                                                <div>
                                                    <label htmlFor="send_time" className="form-label">
                                                        Hora de Envio <span className="text-danger">*</span>
                                                    </label>
                                                    <Flatpickr
                                                        id="send_time"
                                                        name="send_time"
                                                        className="form-control"
                                                        options={{
                                                            locale: flatpickrLocale,
                                                            enableTime: true,
                                                            noCalendar: true,
                                                            time_24hr: true,
                                                        }}
                                                        onChange={(dates, currentTimeString) => handleChangeSendTime(currentTimeString)}
                                                        onChangeCapture={(e) => !e.currentTarget.value && handleChangeSendTime('19:00')}
                                                        value={formData.send_time}
                                                    />
                                                    {!!formValidationMessages.send_time && (
                                                        <FormFeedback>
                                                            {formValidationMessages.send_time}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={3} className="mt-4">
                                                <div className="form-check form-switch form-switch-secondary">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="active"
                                                        name="active"
                                                        onChange={(e) => handleChangeActive(e.target.checked)}
                                                        checked={formData.active === 'Y'}
                                                    />
                                                    <label className="form-check-label" htmlFor="active">Ativo</label>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <Row className="mt-3">
                                            <Col>
                                                <Alert color="primary">
                                                    <p>
                                                        <strong>Digite as variáveis abaixo para customizar a mensagem</strong>
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            <strong>{'{{nome_eleitor}}'}</strong> - Será substituido pelo nome completo do eleitor
                                                        </li>
                                                        <li>
                                                            <strong>{'{{idade}}'}</strong> - Será substituido pela idade do eleitor
                                                        </li>
                                                    </ul>
                                                </Alert>
                                            </Col>
                                        </Row> */}
                                        <Row>
                                            <Col lg={6}>
                                                <div>
                                                    <label htmlFor="message" className="form-label">
                                                        Mensagem
                                                    </label>
                                                    <textarea
                                                        className={`form-control ${!!formValidationMessages.message ? 'is-invalid' : ''}`}
                                                        id="message"
                                                        name="message"
                                                        onChange={handleChangeMessage}
                                                        value={formData.message}
                                                    ></textarea>
                                                    {!!formValidationMessages.message && (
                                                        <FormFeedback>
                                                            {formValidationMessages.message}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <label htmlFor="exampleFormControlTextarea5" className="form-label">Anexar Mídia</label>
                                                <FilePond
                                                    files={files}
                                                    onupdatefiles={(fileItems) => setFiles([...fileItems.map((fileItem) => fileItem.file as File)])}
                                                    allowReorder={true}
                                                    allowMultiple={false}
                                                    labelIdle={`
                                                        <div class="d-flex flex-row align-items-center justify-content-center">
                                                            <img src="/icons/upload.png" />
                                                            <b style="color: #888; margin-left: 15px">Arraste ou clique para upload da foto</b>
                                                        </div>
                                                    `}
                                                    id="fileUpload"
                                                    name="fileUpload"
                                                />

                                            </Col>
                                        </Row>
                                        <button type="submit" className="btn btn-primary waves-effect waves-light">Salvar</button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex justify-content-between">
                                        <h4 className="card-title mb-0 flex-grow-1">Aniversariantes do Dia</h4>
                                    </div>
                                </CardHeader>

                                <CardBody>
                                    <BaseExampleVoters
                                        tableData={data}
                                        rowsPerPage={10}
                                        handlePageChange={handlePageChange}
                                        handleRowsPerPageChange={handleRowsPerPageChange}
                                        total={totalRows}
                                        isLoading={isLoading}
                                        handleSort={handleSort}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
